<template>
 <div class="register1" v-if="isMobile">
    <div class="title-register">注册交易</div>
    <el-row type="flex" class="row-bg" justify="center">
      <el-col :span="24"
        ><div class="form-register">
          <el-form
            :label-position="labelPosition"
            label-width="50px"
            :model="form"
          >
            <el-form-item>
              <el-input v-model="form.name" placeholder="请输入姓名22"></el-input>
            </el-form-item>
            <el-form-item>
              <el-input
                v-model="form.mobile"
                placeholder="请输入手机号"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-input
                v-model="form.email"
                placeholder="请输入邮箱"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-input
                v-model="form.invite_code"
                placeholder="请输入邀请码"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-row :gutter="40">
                <el-col :span="8">
                  <div class="sfzbj zm">
                    <el-upload
                      class="avatar-uploader"
                      action="https://api.trdrgwin.com/prod-api/api/upload/image"
                      :show-file-list="false"
                      :on-success="handleZmSuccess"
                      :before-upload="beforeAvatarUpload"
                    >
                      <img v-if="imageUrlZm" :src="imageUrlZm" class="avatar" height="111"
                        width="163"/>
                      <img
                        v-else
                        src="@/assets/register/zm.png"
                        height="111"
                        width="130"
                      />
                    </el-upload>
                  </div>
                  <div class="zm-tip">拍摄正面</div>
                </el-col>
                <el-col :span="8"
                  ><div class="sfzbj fm">
                    <el-upload
                      class="avatar-uploader"
                      action="https://api.trdrgwin.com/prod-api/api/upload/image"
                      :show-file-list="false"
                      :on-success="handleFmSuccess"
                      :before-upload="beforeAvatarUpload"
                    >
                      <img v-if="imageUrlFm" :src="imageUrlFm" class="avatar" height="111"
                        width="163"/>
                      <img
                        v-else
                        src="@/assets/register/fm.png"
                        height="111"
                        width="130"
                      />
                    </el-upload>
                  </div>
                  <div class="zm-tip">拍摄反面</div></el-col
                >
                <el-col :span="8"
                  ><div class="sfzbj fm">
                    <el-upload
                      class="avatar-uploader"
                      action="https://api.trdrgwin.com/prod-api/api/upload/image"
                      :show-file-list="false"
                      :on-success="handlePSuccess"
                      :before-upload="beforeAvatarUpload"
                    >
                      <img v-if="imageUrlP" :src="imageUrlFm" class="avatar" height="111"
                        width="163"/>
                      <img
                        v-else
                        src="@/assets/register/fm.png"
                        height="111"
                        width="163"
                      />
                    </el-upload>
                  </div>
                  <div class="zm-tip">手持身份证照片</div></el-col
                >
              </el-row>
            </el-form-item>
            <el-form-item>
              <div class="submit-button" @click="register">提交</div>
            </el-form-item>
          </el-form>
        </div></el-col
      >
    </el-row>
  </div>

  <div class="register" v-else>
    <div class="title-register">注册交易</div>
    <el-row type="flex" class="row-bg" justify="center">
      <el-col :span="24"
        ><div class="form-register">
          <el-form
            :label-position="labelPosition"
            label-width="80px"
            :model="form"
          >
            <el-form-item>
              <el-input v-model="form.name" placeholder="请输入姓名"></el-input>
            </el-form-item>
            <el-form-item>
              <el-input
                v-model="form.mobile"
                placeholder="请输入手机号"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-input
                v-model="form.email"
                placeholder="请输入邮箱"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-input
                v-model="form.invite_code"
                placeholder="请输入邀请码"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-row :gutter="40">
                <el-col :span="8">
                  <div class="sfzbj zm">
                    <el-upload
                      class="avatar-uploader"
                      action="https://api.trdrgwin.com/prod-api/api/upload/image"
                      :show-file-list="false"
                      :on-success="handleZmSuccess"
                      :before-upload="beforeAvatarUpload"
                    >
                      <img v-if="imageUrlZm" :src="imageUrlZm" class="avatar" height="111"
                        width="163"/>
                      <img
                        v-else
                        src="@/assets/register/zm.png"
                        height="111"
                        width="163"
                      />
                    </el-upload>
                  </div>
                  <div class="zm-tip">拍摄正面</div>
                </el-col>
                <el-col :span="8"
                  ><div class="sfzbj fm">
                    <el-upload
                      class="avatar-uploader"
                      action="https://api.trdrgwin.com/prod-api/api/upload/image"
                      :show-file-list="false"
                      :on-success="handleFmSuccess"
                      :before-upload="beforeAvatarUpload"
                    >
                      <img v-if="imageUrlFm" :src="imageUrlFm" class="avatar" height="111"
                        width="163"/>
                      <img
                        v-else
                        src="@/assets/register/fm.png"
                        height="111"
                        width="163"
                      />
                    </el-upload>
                  </div>
                  <div class="zm-tip">拍摄反面</div></el-col
                >

                <el-col :span="8"
                  ><div class="sfzbj fm">
                    <el-upload
                      class="avatar-uploader"
                      action="https://api.trdrgwin.com/prod-api/api/upload/image"
                      :show-file-list="false"
                      :on-success="handlePSuccess"
                      :before-upload="beforeAvatarUpload"
                    >
                      <img v-if="imageUrlP" :src="imageUrlP" class="avatar" height="111"
                        width="163"/>
                      <img
                        v-else
                        src="@/assets/register/fm.png"
                        height="111"
                        width="163"
                      />
                    </el-upload>
                  </div>
                  <div class="zm-tip">手持身份证照片</div></el-col
                >
              </el-row>
            </el-form-item>
            <el-form-item>
              <div class="submit-button" @click="register">提交</div>
            </el-form-item>
          </el-form>
        </div></el-col
      >
    </el-row>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      isMobile: false,
      imageUrlZm: '',
      imageUrlFm: '',
      imageUrlP: '',
      labelPosition: 'top',
      form: {
        name: '',
        mobile: '',
        email: '',
        imageUrlZm: '',
      imageUrlFm: '',
      imageUrlP: '',
      source: 'tr',
      invite_code: '',
      },
    }
  },
  watch: {
    isMobile(newValue, oldValue) {
      console.log(`isMobile的值从 ${oldValue} 变为 ${newValue}`);
    }
  },
  mounted () {
    // 根据不同路由跳转不同页面
    if (this._isMobile()) {
      console.log('手机端')
      this.isMobile = true
    } else {
      console.log('pc端')
      this.isMobile = false
    }
  },
  methods: {
    _isMobile () {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag
    },
    handleZmSuccess(res, file) {
      this.imageUrlZm = URL.createObjectURL(file.raw)
      console.log(this.imageUrlZm)
      console.log(file.raw)
      console.log(res)
      this.form.imageUrlZm = res.data.uri
    },
    handleFmSuccess(res, file) {
      this.imageUrlFm = URL.createObjectURL(file.raw)
      this.form.imageUrlFm = res.data.uri
    },
    handlePSuccess(res, file) {
      this.imageUrlP = URL.createObjectURL(file.raw)
      this.form.imageUrlP = res.data.uri
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 20

      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 20MB!')
      }
      return isLt2M
    },
    register() {
      axios
        .post('https://api.trdrgwin.com/prod-api/api/login/newRegister',{
          "name":this.form.name,
          "mobile":this.form.mobile,
          "email":this.form.email,
          "source": this.form.source,
          "invite_code": this.form.invite_code,
          "id_card_front_url":this.form.imageUrlZm,
          "id_card_back_url":this.form.imageUrlFm,
          "id_card_person_url":this.form.imageUrlP
        })
        .then((response) => {
          // 请求成功时的处理
          if (response.data.code == 1) {
            this.$message({
              message:
                '感谢您的注册，您提交的资料正在审核中，审核通过之后我们会尽快跟您联系并且开立账号，谢谢！',
              type: 'warning',
              offset: 300,
            })
          } else {
            this.$message({
              message: response.data.msg,
              type: 'warning',
              offset: 300,
            })
          }
        })
        .catch((error) => {
          // 请求失败时的处理
          console.log(error)
          this.$message({
          message: '注册失败！',
          type: 'error',
          offset:300
        });
        })
      
      console.log(this.form)
     
    },
  },
}
</script>

<style scoped>
.register {
  width: 600px;
  /* height: 500px; */
  margin: 0 auto;
  margin-top: 100px;
}

.register1 {
  /* height: 500px; */
  margin: 0 auto;
  margin-top: 100px;
  margin-left: 15px;
  margin-right: 15px;
}

.title-register {
  font-size: 42px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #323232;
  line-height: 59px;
  margin-bottom: 30px;
}

.sfzbj {
  height: 135px;
  background: #f7f8fc;
  margin-top: 20px;
  padding-top: 30px;
}

.zm-tip {
  height: 43px;
  background: #8fb5f8;
}

.submit-button {
  height: 75px;
  background: linear-gradient(224deg, #ff6001 0%, #fe6c04 100%);
  border-radius: 37px;
  line-height: 75px;
  font-size: 27px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
</style>
